import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ListGroup from "Components/ListGroup";
import ErrorIcon from "Icons/ErrorIcon";

const ErrorMessageWrapper = styled.div`
  position: relative;
  .error-wrapper {
    width: 100%;
    padding: 16px;
    display: inline-block;
    box-sizing: border-box;
    border: 1px solid ${props => props.theme.error};
    margin: 32px 0;
    .icon,
    .icon svg {
      width: 24px;
      height: 24px;
    }
    .icon {
      float: left;
      margin-right: 20px;
    }
    pre {
      line-height: 21px;
      font-size: 12px;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      white-space: pre;
      margin-right: 0;
      margin-bottom: 20px;
      padding: 15px;
      border-radius: 4px;
      text-align: left;
    }
  }
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({
      hasError: true,
      error: error,
      info: info
    });
    const spinner = document.getElementById("pre-loading");
    if (spinner) {
      spinner.remove();
    }
  }

  render() {
    const { children } = this.props;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorMessageWrapper>
          <ListGroup className="error-wrapper" role="alert">
            <ErrorIcon /> {this.state.error.message}
          </ListGroup>
          {children}
        </ErrorMessageWrapper>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node
};

export default ErrorBoundary;
