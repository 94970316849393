import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";

import logger from "Libs/logger";

import { loadProject } from "Reducers/project";

import ProjectLayout from "Components/ProjectLayout";
import ErrorBoundary from "Components/ErrorBoundary";

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hassError: false };
  }
  componentDidMount() {
    this.props.loadProject();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.params.projectId !== nextProps.params.projectId) {
      this.props.loadProject(nextProps.params.projectId);
    }
  }

  componentDidCatch(error) {
    this.setState({
      hasError: true
    });
    logger(error);
  }

  render() {
    return (
      <ErrorBoundary>
        <PageLayout>
          {this.state.hasError && <div>Oops, there was an error.</div>}
          <ProjectLayout>{this.props.children}</ProjectLayout>
        </PageLayout>
      </ErrorBoundary>
    );
  }
}

Project.propTypes = {
  params: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired,
    environmentId: PropTypes.string
  }),
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  children: PropTypes.node.isRequired,
  loadProject: PropTypes.func
};

const mapDispatchToProps = (dispatch, props) => ({
  loadProject: (projectId = props.params.projectId) =>
    dispatch(loadProject(projectId))
});

export default connect(
  undefined,
  mapDispatchToProps
)(Project);
